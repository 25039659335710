import * as moment from 'moment';
import { BaseModel } from './baseModel';
import { Circle } from './circle';
import { Document } from './document';
import { User } from './user';
import { Room } from './room';
import { Task } from './task';
import { authId, userSort } from 'app/common/helpers';
import { CirclesRepository, SettingsRepository, SpheresRepository } from 'app/repositories';

export interface TaskCompletions {
  done: number;
  todo_safe: number;
  todo_warning: number;
  todo_danger: number;
  total: number;
}
export interface AgendaItem {
  line: string;
  level: number;
}

// Cannot extend ModelWithUsers at the moment because that apparently introduces a circular dependency (2022-03-23).
export class Meeting extends BaseModel {

  static STATUS_PREPARING = 0;
  static STATUS_READY_TO_MEET = 10;
  static STATUS_ONGOING = 20;
  static STATUS_FINISHED = 30;
  static STATUS_LOCKED = 90;

  // They all need a default value, because otherwise they don't show up in Object.getOwnPropertyNames(this) during creation.
  id = 0;
  owner_id = 0;
  stack_id: number = null;
  number_of_meetings_in_stack = 1;
  format_id = 1; // 1 is simple, 2 is with invitations etc
  status = Meeting.STATUS_PREPARING;
  updated_at = 0;
  locked = 0; // Timestamp (seconds).
  title = '';
  date_time = 0; // Timestamp (seconds).
  show_time = false;
  duration: number = null; // minutes
  location = '';
  agenda: AgendaItem[] = null;
  report = '';
  share_code = '';
  hide_attendees = false;

  includes_me = true;

  room: Room = null; // room_id in DB, but always object here
  documents: Document[] = [];
  task_histories: { task: Task, chapter: string }[] = [];
  tasks_completion: TaskCompletions = null;

  stack: Meeting[] = null; // Siblings in the stack. (Also includes itself, if it is not the most recent one.)
  date = ''; // Only for display purposes.
  time = ''; // Only for display purposes.
  parent_id: number; // Only ever set temporarily, when creating a follow-up meeting.
  skip_emails: boolean; // Temporary local variable

  // Fields depending on the User who is viewing.
  labels: string[] = [];
  category = 0;

  // Careful: these are not full Circle objects.
  circles = [] as { id: number, swimlane_id: number, sort_in_swimlane?: number }[];

  excerpt = ''; // Only if a Task's CreatorMeeting.
  faded = false;

  private _users: User[] = null;
  private _circle: Circle;

  constructor(object?: Partial<Meeting>) {
    super();
    this.fill(object);

    if (object && typeof object === 'object') {
      if (object?.users?.length) {
        this.setUsers(object.users);
      }

      // Make the Document list into real Document objects.
      if (this.documents.length) {
        this.documents = Document.parseObjects(this.documents);
      }

      // Room
      if (this.room) {
        this.room = new Room(this.room);
        if (this.room.id === 1 && !this.room.video_link) {
          this.room = null;
          // this.room.video_link = 'https://videoroom.flxion.com'
        }
      }

      // Labels
      if (this.labels?.length > 1) {
        this.labels = [...new Set(this.labels)];
      }

      // Other
      if (this.format_id === 1 && !this.status) {
        this.status = Meeting.STATUS_ONGOING;
      }
    }

    this.setDateAndTime();
  }

  get circle(): Circle {
    if (this._circle) {
      return this._circle; // todo: may get out of date}
    }
    if (!this.circles?.length) {
      return null;
    }
    // There may be multiple Circles. Prefer the one closest to the active context.
    const circlesRepo = CirclesRepository.instance;
    const activeCircleId = circlesRepo.getActiveId();
    const activeSphereId = SpheresRepository.instance.getActiveId();
    const circleObjects = this.circles.map(c => circlesRepo.get(c.id)).filter(c => c !== null);
    const circleId = circleObjects.length ? circleObjects.sort(
        (a, b) => a.id === activeCircleId ? -1 : (b.id === activeCircleId ? 1 : (
            a.sphere_id === activeSphereId ? -1 : (b.sphere_id === activeSphereId ? 1 : 0)
        ))
    )[0].id : null;
    return this._circle = circlesRepo.circles.find(circle => circle.id === circleId);
  }

  get circle_ids() {
    return this.circles?.map(circle => circle.id);
  }
  get swimlane_id() {
    return this.circles?.find(
        c => c.id === CirclesRepository.instance.getActiveId()
    )?.swimlane_id;
  }

  // There is no setter for 'users'; use setUsers(). (This makes it more explicit something more is going on there.)
  get users() {
    return this._users;
  }

  get dateTime(): moment.Moment {
    // This applies the device's timezone.
    return this.date_time ? moment.unix(+this.date_time) : null;
  }

  // Methods to help Meetings appear on My Task Planning.
  get planned_date() {
    return this.dateTime;
  }
  get plan_mode() {
    return this.show_time ? 'hour' : 'day';
  }
  get planIsoDate() {
    return this.dateTime?.toISOString() || '';
  }
  get estimated_hours() {
    return this.duration ? this.duration / 60 : 0;
  }

  get owner(): User {
    return this.users?.find(x => x.id === this.owner_id);
  }

  get attendees(): User[] {
    return this.users?.filter(x => x.access_level > 0);
  }

  get viewers(): User[] {
    return this.users?.filter(x => x.access_level === 0);
  }

  get writer(): User {
    return this.users?.find(x => x.access_level === 2);
  }

  get myUser(): User {
    return this.users?.find(x => x.id === authId());
  }

  // Check whether this Meeting was updated within the past 150 seconds.
  get isActive(): boolean {
    return this.updated_at > Date.now() / 1000 - 150;
  }

  // Check whether the logged-in user can edit this Meeting's report.
  // Warning: do not use this for one-time synchronous calls, because .user may not have loaded yet.
  get canWrite(): boolean {
    return !this.id || (!this.locked && this.writer?.id === authId());
  }

  // Can add Documents or Tasks?
  get canAttach(): boolean {
    return !this.locked && this.myUser?.access_level && (!this.hide_attendees || this.canWrite);
  }

  get isOwner(): boolean {
    return !this.id || (this.owner_id === authId());
  }

  get isFinished(): boolean {
    return this.status >= Meeting.STATUS_FINISHED;
  }

  get isLocked(): boolean {
    return this.locked > 0;
  }

  get canUnlock(): boolean {
    return this.locked > Date.now() / 1000;
  }

  get shareLink(): string {
    if (!this.share_code) {
      return '';
    }
    return window.location.origin + '/meetings/' + this.id + '#' + this.share_code;
  }

  get requiresResponse(): boolean {
    return this.format_id === 2 && this.myUser?.isMember;
  }

  get invitationsSent(): boolean {
    return this.format_id > 1 && this.status >= Meeting.STATUS_READY_TO_MEET;
  }

  // This merely sets .date and .time, for presentation purposes.
  setDateAndTime(): void {
    const dateTime = this.dateTime;
    if (dateTime) {
      // Set 'date' and 'time' fields.
      this.date = dateTime.format('YYYY-MM-DD');
      this.time = this.show_time ? dateTime.format('H:mm') : ''
    }
  }

  // Recreate User objects; check includes_me; sort list.
  setUsers(users: User[]) {
    // Make the new User list into real User objects.
    if (users?.length) {
      users = User.parseObjects(users);
      this.includes_me = users.some(x => x.id === authId());
    } else {
      users = null;
      this.includes_me = false;
    }
    this._users = users?.sort(userSort);
  }

  primeForFollowUp() {
    this.parent_id = this.id;
    this.number_of_meetings_in_stack++;
    this.id = 0;
    this.updated_at = 0;
    this.locked = 0;
    this.status = this.format_id === 1 ? Meeting.STATUS_ONGOING : Meeting.STATUS_PREPARING;
    this.report = '';
    this.share_code = '';
    this.documents = [];
    this.task_histories = [];
    this.tasks_completion = null;

    // Clear date, but keep time (the display field). Controller must soon focus the date field.
    if (this.format_id === 1) {
      this.date_time = Math.floor(Date.now() / 1000);
      this.setDateAndTime();
    } else {
      this.date_time = 0;
      this.date = '';
    }

    // Add an incrementing number at the end of the title.
    const match = this.title.match(/ \(([\d]+)\)$/);
    if (match) {
      this.title = this.title.substr(0, this.title.length - match[0].length) + ` (${+match[1] + 1})`;
    } else {
      this.title += ' (2)';
    }

    // Reset Users from that previous meeting. They will be invited as soon as the Meeting is stored.
    this._users.forEach(user => {
      if (user.id === authId()) {
        // Just in case someone else was made writer, bring it back to the owner.
        user.access_level = 2;
        return;
      }
      user.response = 0;
      user.last_viewed = 0;
      if (user.access_level > 1) {
        // Make sure only the owner is writer.
        user.access_level = 1;
      }
    });
  }

  durationFormat(format: ':' | 'h') {
    if (this.duration) {
      const hours = Math.floor(this.duration / 60);
      const minutes = this.duration % 60;
      if (format === 'h') {
        const result = [];
        if (hours) {
          result.push(hours + 'h');
        }
        if (minutes) {
            result.push(minutes + '\'');
        }
        return result.join(' ');
      }
      return hours + ':' + ('00' + minutes).slice(-2);
    }
    return '';
  }

  // Get the Circle Code, possibly including the Sphere code.
  getCircleCode(hideIfActive = false): string {
    if (!CirclesRepository.instance.loaded.value || !SpheresRepository.instance.loaded.value) {
      return '';
    }

    const circle = this.circle;
    if (!circle) {
      return ''; // Formerly: __ › __
    }

    // Only show the CircleCode if it's not the currently active context.
    if (hideIfActive && SettingsRepository.instance.contextActive$.value && CirclesRepository.instance.getActiveId() === circle.id) {
      return '';
    }

    return circle.getSpherePrefix() + (circle.code || circle.title);
  }

  getHeaderTooltip(): string {
    const parts: string[] = [this.title, ''];

    parts.push('Circle:  ' + (this.circle ? this.circle.title : 'No circle assigned yet.'));
    parts.push('Sphere:  ' + (this.circle?.sphere ? this.circle.sphere?.title : 'No sphere assigned yet.'));

    return parts.join('\n');
  }

}
