import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, of } from 'rxjs';
import { auditTime, map, tap } from 'rxjs/operators';
import { Store, createState, withProps, select } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  deleteEntities,
  getEntities,
  getEntity,
  hasEntity,
  selectAll,
  selectEntity,
  setEntities,
  updateEntities,
  withEntities
} from '@ngneat/elf-entities';
import * as moment from 'moment';
import { Meeting } from 'app/models';
import { CirclesRepository } from './circles.repository';

const { state, config } = createState(
  withEntities<Meeting>(),
  withProps<{searchTerm: string}>({searchTerm: ''}),
  withProps<{searchLabels: string[]}>({searchLabels: []}),
);

@Injectable({ providedIn: 'root' })
export class MeetingsRepository {

  private store = new Store({ name: 'meetings', state, config });

  // Why auditTime? Because the Meetings are loaded in stages,
  // and in each stage, every single Meeting is updated. We don't
  // want to broadcast meeting$ each time, only when they are all updated.
  meetings$ = this.store.pipe(
    selectAll(),
    auditTime(50),
    map((meetings: Meeting[]) => Meeting.parseObjects(meetings))
  );
  mustReloadMeetings = false;
  loaded = false;

  searchTerm$ = this.store.pipe(select(state => state.searchTerm));
  searchLabels$ = this.store.pipe(select(state => state.searchLabels));

  get meetings(): Meeting[] {
    return Meeting.parseObjects(this.store.query(getEntities()));
  }

  constructor(
    private circlesRepo: CirclesRepository,
    private http: HttpClient,
  ) {}

  // Load all Meetings in a few phases. Save to the Store after each phase. Return via Observable after the first phase.
  // 1. The basic info.
  // 2. Two separate calls to get the Users and TaskInfo.
  loadAll() {
    // Reset stores.
    if (this.store.query(getEntities())?.length) {
      this.store.update(deleteAllEntities());
      this.circlesRepo.circles.filter(c => c.all_meetings_loaded).forEach(
        c => this.circlesRepo.updateLocal(c.id, { all_meetings_loaded: false })
      );
      this.loaded = false;
    }

    return this.http.get<{data: Meeting[]}>(`/api/meetings`).pipe(
      tap(x => {
        this.store.update(setEntities(x.data));
        this.loaded = true;
        // Step 1 done.
        // Now launch the next two requests.
        combineLatest([
          this.http.get<any>(`/api/meetings?with=users`)
            .pipe(
              map(res => Meeting.parseObjects(res.data)),
              tap(meetings => meetings.forEach(
                meeting => this.updateLocal(meeting.id, { users: meeting.users, labels: meeting.labels })
              ))
            ),
          this.http.get<any>(`/api/meetings?with=task_info`)
            .pipe(
              map(res => Meeting.parseObjects(res.data)),
              tap(meetings => meetings.forEach(
                meeting => this.updateLocal(meeting.id, { tasks_completion: meeting.tasks_completion })
              ))
            )
        ]).subscribe();  // Step 2 done.
      })
    );
  }

  add(data: any) {
    return this.http.post<any>(`/api/meetings`, this.sanitize(data))
      .pipe(
        map(x => new Meeting(x.data)),
        tap(meeting => this.store.update(addEntities(meeting)))
      );
  }

  get(id: number): Meeting|null {
    const object = this.store.query(getEntity(id));
    return object?.id ? new Meeting(object) : null;
  }
  select(id: number) {
    return this.store.pipe(selectEntity(id), map(entity => new Meeting(entity)));
  }
  fetch(id: number, shareCode: string = null, force = false) {
    if (!force && this.store.query(hasEntity(id))) {
      return of(this.get(id));
    }
    return this.http.get<any>(`/api/meetings/${id}` + (shareCode ? '?share_code=' + shareCode : ''))
      .pipe(
        map(x => new Meeting(x.data)),
        tap(meeting => {
          this.store.update(
            this.store.query(hasEntity(id))
            ? updateEntities(id, meeting)
            : addEntities(meeting)
          );
        })
      );
  }

  updateLocal(id: Meeting['id'], meeting: Partial<Meeting>) {
    this.store.update(updateEntities(id, meeting));
  }

  update(id: Meeting['id'], meeting: Partial<Meeting>) {
    return this.http.put<any>(`/api/meetings/${id}`, this.sanitize(meeting))
      .pipe(
        map(x => {
          this.updateLocal(id, x.data);
          return this.get(id);
        }),
      );
  }

  delete(id: Meeting['id']): void {
    this.store.update(deleteEntities(id));
  }

  setSearchTerm(searchTerm: string): void {
    this.store.update(state => ({
      ...state,
      searchTerm
    }));
  }

  setSearchLabels(searchLabels: string[]): void {
    this.store.update(state => ({
      ...state,
      searchLabels
    }));
  }

  // This is currently only used for the 'My Planning' page, where they appear as readonly.
  // That is why this currently does not interact with the Store.
  getPlanned(): Observable<Meeting[]> {
    return this.http.get<any>(`/api/meetings?filter=planned`)
      .pipe(
        map(x => Meeting.parseObjects(x.data)),
      );
  }

  // Called after Meetings are loaded, or one may have switched circles.
  countMeetingsPerCircle(): void {
    this.circlesRepo.circles.forEach(circle => {
      const numMeetings = this.meetings.filter(meeting => meeting.circle_ids.includes(circle.id)).length;
      if (numMeetings !== circle.num_meetings) {
        this.circlesRepo.updateLocal(circle.id, { num_meetings: numMeetings });
      }
    });
  }

  updateCategorization(id: number, labels: string[], category: number) {
    return this.http.put<{ labels: string[], category: number }>(`/api/meetings/${id}/categorize`, { labels, category }).pipe(
      tap(data => {
        this.updateLocal(id, {
          labels: data.labels,
          category: data.category,
        });
      })
    );
  }

  // Multiple Circles can be associated with a Meeting, but only one Circle that the user can edit.
  updateCircleId(id: number, circle_id: number) {
    return this.http.put<any>(`/api/meetings/${id}/circle`, { circle_id }).pipe(
      tap(x => {
        this.updateLocal(id, { circles: x.data });
        this.countMeetingsPerCircle();
      })
    );
  }

  loadAllForCircleId(circleId: number, except: number[] = []): boolean {
    if (circleId <= 0) return;

    // Ensure the circle is known.
    if (!this.circlesRepo.get(circleId)?.id) {
      console.warn('Cannot load meetings for circle: circle not found.', circleId);
      return false;
    }

    this.http.post<any>(`/api/circles/${circleId}/get-meetings`, { except })
      .pipe(
        map(x => x.data),
        tap(meetings => {
          meetings.forEach(data => {
            if (this.store.query(hasEntity(data.id))) {
              this.updateLocal(data.id, data);
            } else {
              this.store.update(addEntities(data));
            }
          });
          this.circlesRepo.updateLocal(circleId, { all_meetings_loaded: true });
        }),
      ).subscribe();
    return true;
  }

  // Called before sending to backend.
  private sanitize(meeting: any): Meeting {
    if (!meeting.date_time) {
      if (typeof meeting.date !== 'string') {
        // Convert Moment date to ISO date format
        meeting.date = meeting.date.format('YYYY-MM-DD');
      }
      // Convert 'date' and 'time' into 'date_time' and 'show_time'.
      let source_string;
      if (meeting.time) {
        meeting.show_time = true;
        if (meeting.time.length === 4) {
          meeting.time = '0' + meeting.time;
        }
        source_string = meeting.date + ' ' + meeting.time;
      } else {
        meeting.show_time = false;
        source_string = meeting.date;
      }
      meeting.date_time = moment(source_string).unix();
    }

    // We don't need to save these.
    delete meeting.date;
    delete meeting.time;
    delete meeting.task_histories;

    return meeting;
  }

}
