import { BaseModel } from './baseModel';
import { User } from './user';
import { authId, userSort } from 'app/common/helpers';

export abstract class ModelWithUsers extends BaseModel {

  id = 0;
  updated_at = 0;
  owner_id = 0;
  title = '';

  private _users: User[] = null;
  // There is no setter for 'users'; use setUsers(). (This makes it more explicit something more is going on there.)
  get users() { return this._users; }
  includes_me = true;
  membersNotDeclined: User[];

  protected constructor(object?: any) {
    super();
    this.fill(object);

    if (typeof object === 'object') {
      let users = [];
      if (object.users?.length) {
        users = users.concat(object.users);
      }
      if (object.visitors?.length) {
        // Circles and Spheres may have 'visitors': linked to child objects.
        // Those that are not in the main users list, are added with access_level: 0.
        users = users.concat(
          (object.visitors as User[])
            .filter(v => !users.some(u => u.id === v.id))
            .map(v => new User(Object.assign({}, v, { access_level: 0 })))
        );
      }
      this.setUsers(users);
    }
  }

  // Recreate User objects; check includes_me; sort list.
  setUsers(users: User[]) {
    // Make the new User list into real User objects.
    if (users?.length) {
      users = User.parseObjects(users);
      this.includes_me = users.some(x => x.response !== 2 && x.id === authId());
    } else {
      users = null;
      this.includes_me = false;
    }
    this._users = users?.sort(userSort);
    this.membersNotDeclined = this._users?.filter(u => u.response !== 2 && u.access_level > 0);
  }

  get myUser(): User {
    return this.users?.find(x => x.id === authId());
  }

  get canWrite(): boolean {
    return !this.id || this.myUser?.access_level === 2;
  }

  get canCreateMeetings(): boolean {
    return !this.id || this.myUser?.access_level > 0;
  }

  get isOwner(): boolean {
    return !this.id || (this.owner_id === authId());
  }

}
