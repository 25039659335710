import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { auditTime, map, tap } from 'rxjs/operators';
import { BehaviorSubject, mergeMap, of, skipWhile, take, throwError } from 'rxjs';
import { Store, createState } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  deleteEntities,
  getActiveId,
  getEntities,
  getEntity,
  hasEntity,
  updateEntities,
  selectActiveEntity,
  selectAll,
  selectEntity,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';

import { CirclesRepository } from './circles.repository';
import { SwimlanesRepository } from './swimlanes.repository';
import { Sphere, User } from 'app/models';
import { authId, authUser } from 'app/common/helpers';

const { state, config } = createState(
  withEntities<Sphere>(),
  withActiveId()
);

@Injectable({ providedIn: 'root' })
export class SpheresRepository extends SwimlanesRepository {

  // To make this service available to Model classes.
  static instance: SpheresRepository;

  // For SwimlaneRepository.
  endpointURL = 'spheres';

  private store = new Store({ name: 'spheres', state, config });
  public loaded = new BehaviorSubject(false);
  get whenLoaded() { return this.loaded.pipe(skipWhile(val => !val), take(1)) }

  activeSphere$ = this.store.pipe(selectActiveEntity(), map(sphere => sphere ? new Sphere(sphere) : null));
  spheres$ = this.store.pipe(selectAll(), auditTime(50), map((spheres: Sphere[]) => Sphere.parseObjects(spheres)));

  get spheres(): Sphere[] {
    return Sphere.parseObjects(this.store.query(getEntities()));
  }

  get activeSphere() {
    return this.get(this.getActiveId());
  }

  constructor(
    protected http: HttpClient,
  ) {
    super();
    SpheresRepository.instance = this;
  }

  loadAll() {
    return this.http.get<{data: Sphere[]}>(`/api/spheres`)
      .pipe(
        tap(x => {
          this.store.update(setEntities(x.data));
          this.loaded.next(true);
        }),
      );
  }

  clearAll() {
    this.store.update(deleteAllEntities());
  }

  reloadAll() {
    this.clearAll();
    return this.loadAll();
  }

  select(id: number) {
    return this.store.pipe(selectEntity(id), map(sphere => new Sphere(sphere)));
  }
  fetch(id: number) {
    return this.http.get<{ data: Sphere }>(`/api/spheres/${id}`)
      .pipe(
        tap(x => {
          if (this.has(id)) {
            this.updateLocal(id, x.data);
          } else {
            this.store.update(addEntities(x.data));
          }
        })
      );
  }
  get(id: number) {
    const object = this.store.query(getEntity(id));
    return object?.id ? new Sphere(object) : null;
  }
  has(id: number) {
    return this.store.query(hasEntity(id));
  }

  add(sphere: Sphere) {
    return this.http.post<any>(`/api/spheres`, sphere)
      .pipe(
        // swimlane_id isn't currently successfully returned from backend.
        map(x => new Sphere(Object.assign({}, x.data, {swimlane_id: sphere.swimlane_id || 0}))),
        tap(sphere => this.store.update(addEntities(sphere)))
      );
  }

  update(id: number, data: Partial<Sphere>, saveToAPI = true) {
    // Update in repo; may be updated again after API save.
    this.store.update(updateEntities(id, data));
    if (saveToAPI) {
      return this.http.put<{ data: Partial<Sphere> }>(`/api/spheres/${id}`, data)
        .pipe(
          map(x => {
            this.store.update(updateEntities(id, x.data));
            return x.data;
          }),
        );
    } else {
      return of(this.get(id));
    }
  }
  updateLocal(id: number, data: Partial<Sphere>) {
    return this.update(id, data, false);
  }

  updateUsers(id: number, users: User[]) {
    const data = users.map(u => {
      let fields: Partial<User> = {id: u.id, label: u.label, access_level: u.access_level};
      if (!u.id) {
        fields.email = u.email;
      }
      return fields;
    });
    return this.http.put<any>(`/api/spheres/${id}/users`, { users: data })
      .pipe(
        map(x => User.parseObjects(x.data)),
        tap(users => this.store.update(updateEntities(id, { users })))
      );
  }

  // Within Users' "My World".
  updateSwimlaneId(id: number, swimlane_id: number) {
    return this.http.put<any>(`/api/spheres/${id}/swimlane`, { swimlane_id })
      .pipe(
        tap(() => {
          this.store.update(updateEntities(id, { swimlane_id }));
        })
      );
  }

  delete(id: number) {
    if (!id) {
      return throwError(() => new Error('Sphere id is missing.'));
    }
    return this.http.delete(`/api/spheres/${id}`).pipe(
      tap(() => this.store.update(deleteEntities(id)))
    );
  }

  leave(id: number, token?: string) {
    if (!id) {
      return throwError(() => new Error('Sphere id is missing.'));
    }
    const data = token ? {token} : {};
    return this.http.post<any>(`/api/spheres/${id}/leave`, data).pipe(
      // We currently reload the whole db to avoid having to figure out
      // whether to leave it as readonly or remove it entirely.
      mergeMap(() => this.reloadAll())
    );
  }

  rejoin(id: number) {
    if (!id) {
      return throwError(() => new Error('Sphere id is missing.'));
    }
    return this.http.post<any>(`/api/spheres/${id}/rejoin`, {}).pipe(
      mergeMap(() => this.reloadAll())
    );
  }

  setActiveId(id: number) {
    if (this.getActiveId() === id) {
      return;
    }

    if (id) {
      // Reset active Circle if it's not in this Sphere.
      const cRepo = CirclesRepository.instance;
      if (cRepo.activeCircle?.sphere_id !== id) {
        cRepo.setActiveId(null);
      }
      localStorage.setItem('sphereId', '' + id);
    } else {
      localStorage.removeItem('sphereId');
    }
    this.store.update(setActiveId(id));
  }

  getActiveId(): number {
    return this.store.query(getActiveId) || 0;
  }

}
