import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Store, createState } from '@ngneat/elf';
import {
  getEntities,
  getEntity,
  selectAll,
  selectEntity,
  setEntities,
  withEntities
} from '@ngneat/elf-entities';
import { User } from 'app/models';
import { byPropsOf } from 'app/common/sort';

const { state, config } = createState(
  withEntities<User>(),
);

@Injectable({ providedIn: 'root' })
export class UsersRepository {

  private store = new Store({ name: 'users', state, config });

  users$ = this.store.pipe(
    selectAll(),
    map((users: User[]) => User.parseObjects(users))
  );
  loaded = false;

  get users(): User[] {
    return User.parseObjects(this.store.query(getEntities()));
  }

  constructor(
    private http: HttpClient,
  ) {
    this.loadAll().subscribe();
  }

  private loadAll() {
    return this.http.get<{data: User[]}>(`/api/users`).pipe(
      map(x => x.data),
      tap(users => {
        users.sort(byPropsOf<User>(['name']));
        this.store.update(setEntities(users));
        this.loaded = true;
      })
    );
  }

  get(id: number): User|null {
    const object = this.store.query(getEntity(id));
    return object?.id ? new User(object) : null;
  }
  select(id: number) {
    return this.store.pipe(selectEntity(id), map(entity => new User(entity)));
  }

}
