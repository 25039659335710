import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { CirclesRepository } from 'app/repositories';
import { Circle, Meeting } from 'app/models';
import { byPropsOf } from 'app/common/sort';

@Component({
  selector: 'flxion-change-circle',
  templateUrl: './change-circle.component.html',
  styleUrls: ['./change-circle.component.scss']
})
export class ChangeCircleComponent implements OnInit, OnDestroy {

  private stopper = new Subject<void>();

  // The Circles I can choose.
  selectableCircles: Circle[] = [];
  // The Circles attached to this Meeting.
  circles: Circle[];

  circleFilter = new FormControl();
  filteredCircles: Observable<Circle[]>;

  oldCircleIds = '[]'; // json

  constructor(
    @Inject(MAT_DIALOG_DATA) public meeting: Meeting,
    private dialogRef: MatDialogRef<ChangeCircleComponent, number>,
    private circlesRepo: CirclesRepository,
    private snack: MatSnackBar,
  ) { }

  ngOnInit() {
    this.circles = this.meeting.circles.map(c => this.circlesRepo.circles.find(circle => circle.id === c.id));

    // Store original values, to determine whether to save later.
    this.oldCircleIds = JSON.stringify(this.meeting.circle_ids);

    // Load the ones we'll be able to choose.
    this.circlesRepo.circles$.pipe(
      map(circles => this.meeting.isOwner ? circles : circles.filter(circle => circle.canWrite)),
      takeUntil(this.stopper)
    ).subscribe(circles => {
      this.selectableCircles = circles.sort(byPropsOf<Circle>(['sphere_id', 'code', 'title']));
      this.filteredCircles = this.circleFilter.valueChanges
        .pipe(
          startWith(null),
          map(value => this._filter(value))
        );
    });

  }

  ngOnDestroy() {
    this.stopper.next();
    this.stopper.complete();
  }

  get dirty() {
    return this.oldCircleIds !== JSON.stringify(this.circles.map(c => c?.id));
  }

  removeCircle(circle: Circle): void {
    if (!circle.canWrite && !this.meeting.isOwner) {
      this.snack.open('You cannot remove a Circle that you do not own.');
      return;
    }
    const index = this.circles.indexOf(circle);

    if (index >= 0) {
      this.circles.splice(index, 1);
    }
  }

  chooseCircle(event: MatAutocompleteSelectedEvent): void {
    // Add selected circle to list.
    const circle: Circle = event.option.value;
    if (!this.circleInList(circle)) {
      // Remove all the other ones I can edit, because we can keep only one.
      if (this.meeting.isOwner) {
        this.circles = [circle];
      } else {
        this.circles = this.circles.filter(c => !c.canWrite);
        this.circles.push(circle);
      }
    }
    // Reset filter.
    this.circleFilter.setValue(null);
  }

  // Check whether the given circle is already in the list of circles.
  private circleInList(newCircle: Circle) {
    return this.circles.some(circle => circle.id === newCircle.id);
  }

  private _filter(value: Circle|string): Circle[] {
    if (!value) {
      return this.selectableCircles;
    }
    if (typeof value !== 'string') {
      value = value.title;
    }

    const lc = value.toLowerCase();
    return this.selectableCircles.filter(
      circle => circle.title.toLowerCase().includes(lc)
        || circle.code?.toLowerCase().includes(lc)
        || circle.sphere?.code?.toLowerCase().includes(lc)
    );
  }

  save() {
    if (this.dirty) {
      if (!this.circles.length) {
        alert('Please select a circle.');
        return;
      }
      // There can only be one self-owned Circle attached to each Meeting.
      // But there may be other Circles, so sort first those I own, then take the first one.
      const newCircleIds = this.circles.sort(byPropsOf<Circle>(['-isOwner'])).map(c => c.id);
      this.dialogRef.close(newCircleIds ? newCircleIds[0] : 0);
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
