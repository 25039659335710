import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { auditTime, map, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Store, createState } from '@ngneat/elf';
import {
  deleteAllEntities,
  getEntities,
  getEntity,
  selectAll,
  selectEntity,
  setEntities,
  withEntities,
} from '@ngneat/elf-entities';

import { UserService } from '../services';
import { CirclesRepository } from './circles.repository';
import { SpheresRepository } from './spheres.repository';
import { SwimlanesRepository } from './swimlanes.repository';
import { Swimlane, UserSettings } from 'app/models';
import { bySort } from '../common/helpers';

const { state, config } = createState(
  withEntities<Swimlane>(),
);

@Injectable({ providedIn: 'root' })
export class SettingsRepository extends SwimlanesRepository {

  // To make this service available to Model classes.
  static instance: SettingsRepository;

  // For SwimlaneRepository.
  endpointURL = 'user';

  protected swimlanesStore = new Store({ name: 'swimlanes', state, config });

  // Swimlanes for spheres.
  swimlanes$ = this.swimlanesStore.pipe(
    selectAll(),
    map((swimlanes: Swimlane[]) => Swimlane.parseObjects(swimlanes))
  );

  get swimlanes(): Swimlane[] {
    return Swimlane.parseObjects(this.swimlanesStore.query(getEntities()));
  }

  contextActive$ = new BehaviorSubject<boolean>(true);
  contextActiveCanToggle$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected http: HttpClient,
    private userService: UserService,
    private circlesRepo: CirclesRepository,
    private spheresRepo: SpheresRepository,
  ) {
    super();
    SettingsRepository.instance = this;

    // Load swimlanes (when logged in).
    this.userService.loggedIn$.subscribe(
      loggedIn => loggedIn && this.loadAll().subscribe()
    );

    // Set initial values for circle_id and sphere_id.
    this.setInitialValues();
    // Watch and save circle_id and sphere_id.
    this.watchAndSave();

    // todo: remove this in 2022-05 or so
    localStorage.removeItem('activeMyOurView');
  }

  private setInitialValues() {
    this.spheresRepo.whenLoaded.subscribe(() => {
      const storedId = this.userService.user.settings.sphere_id;
      if (storedId && this.spheresRepo.has(storedId)) {
        this.spheresRepo.setActiveId(storedId);
      }
    });

    this.circlesRepo.whenLoaded.subscribe(() => {
      const storedId = this.userService.user.settings.circle_id;
      if (storedId && this.circlesRepo.has(storedId)) {
        this.circlesRepo.setActiveId(storedId);
      }
    });
  }

  /**
   * Watch changes to the active context, and persist changes to remote DB.
   */
  private watchAndSave() {
    combineLatest([
      this.circlesRepo.activeCircle$,
      this.spheresRepo.activeSphere$
    ]).pipe(
      // Avoid spamming the API:
      auditTime(2000)
    ).subscribe(([activeCircle, activeSphere]) => this.persistUserSettings({
      circle_id: activeCircle?.id,
      sphere_id: activeSphere?.id
    }));
  }
  private persistUserSettings(settings: Partial<UserSettings>) {
    const curSettings = this.userService.user.settings;

    let hasDifference = false;
    for (let prop of Object.getOwnPropertyNames(settings)) {
      if (settings[prop] !== curSettings[prop]) {
        hasDifference = true;
        break;
      }
    }
    if (hasDifference) {
      this.userService.updateProfile({ settings: Object.assign({}, curSettings, settings) }).subscribe();
    }
  }

  private loadAll(): Observable<Swimlane[]> {
    return this.http.get<any>(`/api/user/swimlanes`)
      .pipe(
        map(x => x.data),
        tap(swimlanes => this.swimlanesStore.update(setEntities(swimlanes.sort(bySort)))),
      );
  }

  clearAll() {
    this.swimlanesStore.update(deleteAllEntities());
  }

  selectSwimlane(id: number) {
    return this.swimlanesStore.pipe(selectEntity(id), map(swimlane => new Swimlane(swimlane)));
  }
  getSwimlane(id: number) {
    return new Swimlane(this.swimlanesStore.query(getEntity(id)));
  }

}
