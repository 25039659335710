import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { catchError, mergeMap, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Meeting, User } from '../models';
import { CirclesRepository, MeetingsRepository } from '../repositories';
import { MeetingService } from './meeting.service';
import { ConfirmService } from '../common/confirm/confirm.service';
import { ChangeSwimlaneComponent } from '../common/change-swimlane/change-swimlane.component';
import { MeetingCategorizeComponent } from '../modules/meetings/dialogs/categorize/meeting-categorize.component';
import { ChangeCircleComponent } from '../modules/meetings/dialogs/change-circle/change-circle.component';
import { ChangeOwnerComponent } from '../modules/meetings/dialogs/change-owner.component';

export type CategorizationData = {
  meetingId: number;
  labels: string[];
  category: number;
};

@Injectable({
  providedIn: 'root'
})
export class MeetingUiService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private repo: MeetingsRepository,
    private circlesRepo: CirclesRepository,
    private confirmService: ConfirmService,
    private meetingService: MeetingService,
  ) { }

  // Method used in both MeetingOverview and MeetingDetails.
  uiUnlockMeeting(meeting: Meeting) {
    if (!meeting.canUnlock) {
      return;
    }

    return this.meetingService.unlock(meeting.id).pipe(
      map(() => {
        meeting.locked = 0;
        meeting.status = Meeting.STATUS_FINISHED;
        this.snack.open('Meeting successfully unlocked.', null, {duration: 2750});
        this.meetingService.activateComponent?.enable();
        return true;
      }),
      catchError(error => {
        if (error.error?.message) {
          this.snack.open(error.error.message, 'OK');
        }
        throw error;
      })
    );
  }

  // Method used in both MeetingOverview and MeetingDetails.
  uiLockMeeting(meeting: Meeting, $event: MouseEvent = null, redirectToOverview = false) {
    if (meeting.isOwner) {
      const andViewers = meeting.format_id === 1 ? '' : ' and viewers';
      return this.confirmService.confirm({
        title: 'Lock meeting report',
        message: `All attendees${andViewers} will soon receive a notification that the report is finalized.`,
        more_info: `You can undo the lock in the next 15 minutes. After that, the lock is final, `+
          `and that's when all attendees${andViewers} will receive the notification.`
      }).pipe(
        mergeMap(res => {
          if (!res) {
            // Cancelled action.
            throw 0;
          }
          return this.meetingService.lock(meeting.id);
        }),
        tap(locked => {
          if (redirectToOverview) {
            this.router.navigate(['/meetings']);
          } else {
            meeting.locked = locked;
            meeting.status = locked
              ? Meeting.STATUS_LOCKED
              : (meeting.format_id === 1 ? Meeting.STATUS_ONGOING : Meeting.STATUS_FINISHED);
            // If the MeetingDetailsComponent is open, disable/enable its form.
            if (locked) {
              this.meetingService.activateComponent?.form.disable();
            } else {
              if (meeting.isOwner) this.meetingService.activateComponent?.form.enable();
            }
          }
          return locked;
        }),
        catchError(error => {
          if (error?.error?.message) {
            this.snack.open(error.error.message, 'OK');
          }
          throw error;
        })
      );
    }

    else {
      // Remind the writer to finalize it.
      return this.confirmService.confirm({
        title: 'Send reminder to finalize',
        message: `This will send an email to the meeting's owner to remind them to finalize this meeting report. Continue?`,
      }).pipe(
        mergeMap(res => {
          if (!res) {
            // Cancelled action.
            throw 0;
          }
          return this.meetingService.remindToFinalize(meeting.id);
        }),
        tap(() => {
          ($event.target as HTMLElement).classList.add('hidden');
          this.snack.open('Email sent!', null, { duration: 2000 });
        }),
        catchError(error => {
          if (error?.error?.message) {
            this.snack.open(error.error.message, 'OK');
          }
          throw error;
        })
      );
    }
  }

  uiOrganizeMeeting(meeting: Meeting) {
    return this.dialog.open(MeetingCategorizeComponent, {
      width: '480px',
      autoFocus: false,
      data: meeting,
    }).afterClosed().pipe(
      mergeMap((data: CategorizationData) => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.updateCategorization(data.meetingId, data.labels, data.category);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Categorization could not be saved.', 'OK');
        }
        throw err;
      })
    );
  }

  uiChangeCircle(meeting: Meeting) {
    return this.dialog.open(ChangeCircleComponent, {
      width: '480px',
      autoFocus: false,
      data: meeting,
    }).afterClosed().pipe(
      mergeMap(circleId => {
        if (circleId === null) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.updateCircleId(meeting.id, +circleId);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Circle association could not be saved.', 'OK');
        }
        throw err;
      })
    );
  }

  uiChangeMeetingOwner(meeting: Meeting) {
    return this.dialog.open(ChangeOwnerComponent, {
      width: '480px',
      autoFocus: false,
      data: meeting,
    }).afterClosed().pipe(
      mergeMap((user: User) => {
        if (!user || user.id === meeting.owner_id) {
          // No changes.
          throw 0;
        }
        return this.meetingService.updateOwner(meeting.id, user.id);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Owner could not be changed.', 'OK');
        }
        throw err;
      })
    );
  }

  uiDeleteMeeting(meeting: Meeting) {
    return this.confirmService.confirm({
      message: `This will permanently delete the meeting report as well as any tasks or documents attached to it.`
    })
      .pipe(mergeMap(res => !res
          ? of(null)
          : this.meetingService.delete(meeting.id)
            .pipe(catchError(error => {
              if (error.error?.message) {
                this.snack.open(error.error.message, 'OK');
              }
              return null;
            }))
        ));
  }

  uiChangeSwimlane(meeting: Meeting) {
    const activeCircle = this.circlesRepo.activeCircle;
    return this.dialog.open(ChangeSwimlaneComponent, {
      width: '480px',
      autoFocus: false,
      data: {
        title: meeting.title,
        swimlaneId: meeting.circles.find(c => c.id === activeCircle.id)?.swimlane_id,
        swimlanes: activeCircle.swimlanes,
      },
    }).afterClosed().pipe(
      mergeMap(data => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.meetingService.updateSwimlaneId(meeting, activeCircle.id, +data.swimlaneId);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Swimlane association could not be saved.', 'OK');
        }
        throw err;
      })
    );
  }

}
